define(['lodash', 'layout/util/layout'], function (_, layout) {
    'use strict';

    const force3Dskins = [
        'wysiwyg.viewer.skins.line.ZigzagLineFlipSkin',
        'wysiwyg.viewer.skins.line.ZigzagLineSkin'
    ];

    const getBorderWidthFromDOM = node => window.getComputedStyle(node).borderTopWidth;
    const getBorderWidthFromStructure = structureInfo => _.get(structureInfo, 'styleItem.style.properties.lnw');
    const getBorderWidth = (structureInfo, node) => parseFloat(getBorderWidthFromStructure(structureInfo) || getBorderWidthFromDOM(node));
    const isForced3DSkin = structureInfo => _.includes(force3Dskins, _.get(structureInfo, 'styleItem.skin'));

    const createRotationPatch = (structureInfo, rotation) => {
        if (isForced3DSkin(structureInfo)) {
            return {transform: `rotate3d(0, 0, 1, ${rotation}deg)`};
        }
        return null;
    };

    function lineLayout(id, nodesMap, measureMap, siteData, structureInfo) {
        const minHeight = measureMap.height[`${id}line`] || 0;
        const borderTopWidth = getBorderWidth(structureInfo, nodesMap[id]);
        measureMap.height[id] = Math.max(5, borderTopWidth, minHeight);
        measureMap.minHeight[id] = minHeight;

        return patchers => {
            const isFulllScreen = _.get(structureInfo, 'propertiesItem.fullScreenModeOn');
            const rotation = _.get(structureInfo, 'layout.rotationInDegrees');

            if (isFulllScreen) {
                patchers.css(id, {width: '100%', left: 0, marginLeft: 0});
            }
            if (rotation) {
                const rotationPatch = createRotationPatch(structureInfo, rotation);
                if (rotationPatch) {
                    patchers.css(id, rotationPatch);
                }
            }
        };
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.FiveGridLine', [['line']]);
    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.FiveGridLine');
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.FiveGridLine', lineLayout);
});
