define([
    'lodash',
    'layout/specificComponents/balataLayout',
    'layout/util/rootLayoutUtils',
    'warmupUtils'
], function (
    _,
    balataLayout,
    rootLayoutUtils,
    warmupUtils
) {
    'use strict';

    /**
     * Do NOT use it and do NOT do something similar !!!
     * And DO NOT DO THIS AT HOME !!!
     *
     * This is temporary special ugly util created for the popups docking.
     * It should be replaced in future by verbs.
     *
     * Ask AlissaV for details.
     **/

    const ALIGNMENT_TYPES = {
        NINE_GRID: 'nineGrid',
        FULL_HEIGHT: 'fullHeight',
        FULL_WIDTH: 'fullWidth'
    };

    const isMesh = domNode => domNode.getAttribute('data-is-mesh') === 'true';

    function getBackgroundDataItem(structureInfo) {
        return _.get(structureInfo.designDataItem, 'background', _.get(structureInfo.dataItem, 'background'));
    }

    function measureStripContainer(id, measureMap, nodesMap, structureInfo) {
        const inlineContentHeight = measureMap.height[`${id}inlineContent`] || 0;
        measureMap.left[id] = 0;
        measureMap.width[id] = measureMap.width.screen;
        measureMap.containerHeightMargin[id] = inlineContentHeight ? nodesMap[id].offsetHeight - inlineContentHeight : 0;

        if (getBackgroundDataItem(structureInfo)) {
            balataLayout.measure(id, measureMap, nodesMap, structureInfo);
        }
    }

    function measureStripContainerBalataOnly(id, measureMap, nodesMap, structureInfo) {
        if (getBackgroundDataItem(structureInfo)) {
            balataLayout.measure(id, measureMap, nodesMap, structureInfo);
        }
    }

    function patchStripContainer(id, patchers, measureMap, structureInfo, siteData) {
        if (getBackgroundDataItem(structureInfo)) {
            patchers.css(id, {left: 0, width: rootLayoutUtils.getRootWidth(siteData.getSiteWidth(), measureMap, structureInfo.rootId)});
            balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
        }
    }

    function patchStripContainerBaltaOnly(id, patchers, measureMap, structureInfo, siteData) {
        if (getBackgroundDataItem(structureInfo)) {
            balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
        }
    }

    const measureBalataOnly = (id, measureMap, nodesMap, structureInfo) => {
        _.set(measureMap.custom, [id, 'patchBaltaOnly'], true);

        const compProps = structureInfo.propertiesItem;
        if (compProps.alignmentType === ALIGNMENT_TYPES.FULL_WIDTH) {
            measureStripContainerBalataOnly(id, measureMap, nodesMap, structureInfo);
            return;
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);
    };

    const measure = (id, measureMap, nodesMap, structureInfo, {siteWidth}) => {
        // there is no sense to set measureMap.top[id] here,
        // because height could and probably will be changed by anchors
        // and therefore top calculation should be done afterwards
        const compProps = structureInfo.propertiesItem;
        let parentDimensions;
        if (compProps.alignmentType === ALIGNMENT_TYPES.FULL_WIDTH) {
            measureStripContainer(id, measureMap, nodesMap, structureInfo);
            return;
        }

        if (compProps.alignmentType === ALIGNMENT_TYPES.FULL_HEIGHT) {
            measureMap.minHeight[id] = measureMap.innerHeight.screen;
            measureMap.height[id] = Math.max(measureMap.height[id], measureMap.minHeight[id]);
            measureMap.shrinkableContainer[id] = true;
            parentDimensions = {
                top: 0,
                left: 0,
                width: measureMap.width[id],
                height: measureMap.innerHeight.screen
            };
        }

        measureMap.left[id] = warmupUtils.compAlignmentUtils.getLeft(compProps, measureMap.width[id], measureMap.width.screen, siteWidth);
        //measureMap.left[id] = siteUtils.popupLayoutUtils.getLeft(compProps, id, measureMap, siteData, compProps.horizontalOffset);
        balataLayout.measure(id, measureMap, nodesMap, structureInfo, parentDimensions);
    };

    const patchBalataOnly = (id, patchers, measureMap, structureInfo, siteData) => {
        const compProps = structureInfo.propertiesItem;

        if (compProps.alignmentType === ALIGNMENT_TYPES.FULL_WIDTH) {
            patchStripContainerBaltaOnly(id, patchers, measureMap, structureInfo, siteData);
        } else {
            balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
        }
    };

    const patch = (id, patchers, measureMap, structureInfo, siteData) => {
        // YES, it violates common principle which is "do NOT write to measureMap on patching, you can only read".
        // This case is very specific for a popup container and you SHOULD NOT do the same or use this.
        // This temporary solution for the docking popup container to the screen and should be removed after verbs are ready.
        // In order for things to work, we need to have actual real position and height in measure map,
        // which can not be obtained before this phase.

        const compProps = structureInfo.propertiesItem;
        const popupPageId = siteData.getCurrentPopupId();
        const newTop = warmupUtils.compAlignmentUtils.getTop(compProps, measureMap.height[id], measureMap.innerHeight.screen);
        const newPageHeight = measureMap.height[popupPageId] = _.max([measureMap.height.screen, newTop + measureMap.height[id]]);

        measureMap.top[id] = newTop;
        patchers.css(popupPageId, {height: newPageHeight});

        patchers.css(id, {
            left: measureMap.left[id],
            top: newTop,
            width: measureMap.width[id],
            height: measureMap.height[id]
        });

        if (compProps.alignmentType === ALIGNMENT_TYPES.FULL_WIDTH) {
            patchStripContainer(id, patchers, measureMap, structureInfo, siteData);
        } else {
            balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
        }
    };

    return {
        measure(id, measureMap, nodesMap, structureInfo, {siteWidth}) {
            return isMesh(nodesMap[id]) ?
                measureBalataOnly(id, measureMap, nodesMap, structureInfo) :
                measure(id, measureMap, nodesMap, structureInfo, {siteWidth});
        },

        patch(id, patchers, measureMap, structureInfo, siteData) {
            const shouldPatchBalataOnly = _.get(measureMap, ['custom', id, 'patchBaltaOnly'], false);
            return shouldPatchBalataOnly ?
                patchBalataOnly(id, patchers, measureMap, structureInfo, siteData) :
                patch(id, patchers, measureMap, structureInfo, siteData);
        }
    };
});
