define(['lodash', 'layout/util/layout'], function (_, /** layout.layout */ layout) {
    'use strict';

    function updateDimensions(id, patchers) {
        patchers.css(id, {left: 0, width: '100%'});
        patchers.css(`${id}iframe`, {width: '100%'});
    }

    function patchCollageGallery(id, patchers, measureMap, {propertiesItem}) {
        if (propertiesItem.fitToScreenWidth) {
            patchers.css(`${id}iframe`, {width: '100%'});
        } else {
            patchers.css(`${id}iframe`, {width: measureMap.width[id]});
        }
    }

    function patchTpaStripGallery(id, patchers, measureMap, structureInfo, siteData) {
        updateDimensions(id, patchers, measureMap, siteData, structureInfo);
    }

    layout.registerRequestToMeasureChildren('tpa.viewer.components.StripShowcase', [['iframe']]);
    layout.registerPatcher('tpa.viewer.components.StripShowcase', patchTpaStripGallery);
    layout.registerRequestToMeasureChildren('tpa.viewer.components.StripSlideshow', [['iframe']]);
    layout.registerPatcher('tpa.viewer.components.StripSlideshow', patchTpaStripGallery);
    layout.registerRequestToMeasureChildren('tpa.viewer.components.Collage', [['iframe']]);
    layout.registerPatcher('tpa.viewer.components.Collage', patchCollageGallery);
});
