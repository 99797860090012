define([
    'zepto',
    'lodash',
    'layout/util/layout',
    'warmupUtilsLib'
], function (
    $,
    _,
    layout,
    warmupUtilsLib
) {
    'use strict';

    const ACTION_CONTAINER = 'actionsContainer';
    const MAX_TEXT_WIDTH = 'maxTextWidth';

    function measureTextWidth(actionBarNode) {
        const actionNodes = $(actionBarNode).find('div[data-quick-action="text"]');
        return _(actionNodes)
            .map(function (el) {
                const textWidth = +_.replace($(el).css('width'), 'px', '');
                return _.isNaN(textWidth) ? 0 : textWidth;
            })
            .max();
    }

    function measureQuickActionBar(id, measureMap, nodesMap, structureInfo, {isMobileDevice, isMobileZoomed}) {
        const siteRootNode = $('#SITE_ROOT')[0];
        const actionBarRect = warmupUtilsLib.domMeasurements.getElementRect(nodesMap[id], siteRootNode);
        measureMap.width[id] = actionBarRect.width;
        measureMap.height[id] = actionBarRect.height;

        const isClosed = _.includes(nodesMap[id].className.split(/\s+/), `${structureInfo.styleItem.id}_closed`);
        measureMap.width[id + MAX_TEXT_WIDTH] = isClosed ? 0 : measureTextWidth(nodesMap[id]);
        const isZoomed = isMobileZoomed && isMobileDevice();
        const hasNoActions = nodesMap[id].classList.contains(`${structureInfo.styleItem.id}_action-count-0`);
        measureMap.custom[id] = {
            isZoomed,
            visibility: isZoomed || hasNoActions ? 'hidden' : 'visible'
        };

        if (isClosed) {
            measureMap.height[id + ACTION_CONTAINER] = actionBarRect.height;
            measureMap.width[id + ACTION_CONTAINER] = actionBarRect.width;
        }
    }

    function patchQuickActionBar(id, patchers, measureMap) {
        patchers.css(id, {
            top: '',
            left: '',
            width: '',
            height: '',
            'z-index': 1001, // must be bigger than 1000, to hide WixAd when it's fixed position.
            visibility: measureMap.custom[id].visibility,
            'pointer-events': measureMap.custom[id].isZoomed ? 'none' : ''
        });
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.QuickActionBar', [[ACTION_CONTAINER]]);
    layout.registerCustomMeasure('wysiwyg.viewer.components.QuickActionBar', measureQuickActionBar);
    layout.registerPatcher('wysiwyg.viewer.components.QuickActionBar', patchQuickActionBar);
});
