define([
    'lodash',
    'warmupUtilsLib',
    'layout/util/layout',
    'layout/specificComponents/balataLayout'],
function (_,
          warmupUtilsLib,
          /** layout.layout */
          layout,
          balataLayout) {
    'use strict';

    const balataConsts = warmupUtilsLib.mediaConsts.balataConsts;

    function preMeasureColumns(structureInfo, myDomNode, measureMap, nodesMap, layoutAPI, measureStructureFunc, enforceAnchorsFunc) {
        const columnStructure = structureInfo.structure;
        const id = columnStructure.id;
        if (layoutAPI.isMesh) {
            return;
        }

        const originalHeight = measureMap.height[id];

        measureStructureFunc(columnStructure);
        measureMap.minHeight[id] = 0; // allow column to shrink
        measureMap.height[id] = columnStructure.layout.height;
        const changedCompsMap = enforceAnchorsFunc(columnStructure);

        if (layoutAPI.isMobileView()) {
            measureMap.top[id] = nodesMap[id].offsetTop;
        }

        if (originalHeight !== measureMap.height[id]) {
            _.set(changedCompsMap, id, true);
        }

        return {
            needsAdditionalInnerLayout: false,
            needsParent: true,
            changedCompsMap: _.mapValues(changedCompsMap, _.constant(true))
        };
    }

    function measureStripColumnsContainer(id, measureMap, nodesMap, structureInfo, {isMobileView, isMesh}) {
        if (isMesh) {
            return balataLayout.measure(id, measureMap, nodesMap, structureInfo);
        }

        const children = warmupUtilsLib.dataUtils.getChildrenData(structureInfo.structure, isMobileView());
        const childrenIds = _.map(children, 'id');

        if (isMobileView()) {
            let containerHeight = 2 * structureInfo.propertiesItem.rowMargin + (children.length - 1) * structureInfo.propertiesItem.columnsMargin; // eslint-disable-line no-mixed-operators

            _.forEach(childrenIds, function (childId, i) {
                containerHeight += measureMap.height[childId];
                measureMap.minHeight[childId] = measureMap.height[childId];

                const isFirstColumn = i === 0;
                const isLastColumn = i === childrenIds.length - 1;

                if (isFirstColumn) {
                    measureMap.top[childId] = structureInfo.propertiesItem.rowMargin;
                }

                if (isLastColumn) {
                    measureMap.injectedAnchors[childId] = [
                        {
                            'fromComp': childId,
                            'distance': structureInfo.propertiesItem.rowMargin,
                            'topToTop': 0,
                            'originalValue': 0,
                            'type': 'BOTTOM_PARENT',
                            'locked': true,
                            'targetComponent': id
                        }
                    ];
                } else {
                    measureMap.injectedAnchors[childId] = [
                        {
                            'fromComp': childId,
                            'distance': structureInfo.propertiesItem.columnsMargin,
                            'locked': true,
                            'originalValue': 0,
                            'targetComponent': childrenIds[i + 1],
                            'topToTop': 0,
                            'type': 'BOTTOM_TOP'
                        }
                    ];
                }
            });

            measureMap.height[id] = containerHeight;
            /* todo we won't need to calculate container height manually once BALATA uses its
                 parent's size (after its been measured and enforced) in the patch phase*/
            measureMap.height[id + balataConsts.BALATA] = containerHeight;
        } else {
            const maxChildHeight = _(measureMap.height)
                .pick(childrenIds)
                .values()
                .max();

            _.forEach(childrenIds, function (childId) {
                measureMap.top[childId] = structureInfo.propertiesItem.rowMargin;
                measureMap.minHeight[childId] = maxChildHeight;
                measureMap.injectedAnchors[childId] = [
                    {
                        'fromComp': childId,
                        'distance': 0,
                        'originalValue': 0,
                        'type': 'BOTTOM_PARENT',
                        'locked': true,
                        'targetComponent': id
                    }
                ];
            });

            measureMap.height[id] = maxChildHeight + 2 * structureInfo.propertiesItem.rowMargin; // eslint-disable-line no-mixed-operators
            measureMap.containerHeightMargin[id] = structureInfo.propertiesItem.rowMargin;
            measureMap.height[id + balataConsts.BALATA] = maxChildHeight + 2 * structureInfo.propertiesItem.rowMargin; // eslint-disable-line no-mixed-operators
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);
    }

    function patchStripColumnsContainer(id, patchers, measureMap, structureInfo, layoutAPI) {
        if (layoutAPI.isMesh) {
            return balataLayout.patch(id, patchers, measureMap, structureInfo, layoutAPI);
        }

        patchers.css(id, {
            height: measureMap.height[id],
            left: 0
        });

        const {fullWidth} = structureInfo.propertiesItem;
        if (fullWidth) {
            patchers.css(id, {width: '100%'});
        }

        balataLayout.patch(id, patchers, measureMap, structureInfo, layoutAPI);
    }

    function measureColumn(id, measureMap, nodesMap, structureInfo, {isMesh}) {
        if (isMesh) {
            return balataLayout.measure(id, measureMap, nodesMap, structureInfo);
        }

        const node = nodesMap[id];
        // even though we put the height in the premeasure,
        // there is another default measure which will take the current node height (and we dont want)

        //!!!should revisit!! - minHeight is set on pre-measure. mesh layout doesnt invoke pre-measure(anchor less).
        // since viewport aspect relay on column height measures...
        // applying height value when minHeight is missing as workaround.
        // WEED-13741
        measureMap.height[id] = measureMap.minHeight[id] || measureMap.height[id];
        measureMap.width[id] = node.offsetWidth;
        balataLayout.measure(id, measureMap, nodesMap, structureInfo);

        measureMap.height[id + balataConsts.BALATA] = measureMap.minHeight[id];
    }

    function patchColumn(id, patchers, measureMap, structureInfo, layoutAPI) {
        if (layoutAPI.isMesh) {
            return balataLayout.patch(id, patchers, measureMap, structureInfo, layoutAPI);
        }

        patchers.css(id, {height: measureMap.height[id]});

        if (!layoutAPI.isMobileView()) {
            patchers.css(id, {left: '', width: '', position: 'relative'});
        }
        balataLayout.patch(id, patchers, measureMap, structureInfo, layoutAPI);
    }

    const COLUMNS_CONTAINER = 'wysiwyg.viewer.components.StripColumnsContainer';
    const COLUMN = 'wysiwyg.viewer.components.Column';

    layout.registerPureDomWidthMeasure(COLUMNS_CONTAINER);
    layout.registerPureDomHeightMeasure(COLUMNS_CONTAINER);
    layout.registerRequestToMeasureChildren(COLUMNS_CONTAINER, balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE);
    layout.registerCustomMeasure(COLUMNS_CONTAINER, measureStripColumnsContainer);
    layout.registerPatcher(COLUMNS_CONTAINER, patchStripColumnsContainer);

    layout.registerPureDomWidthMeasure(COLUMN);
    layout.registerPureDomHeightMeasure(COLUMN);
    layout.registerRequestToMeasureChildren(COLUMN, balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE);
    layout.registerLayoutInnerCompsFirst(COLUMN, preMeasureColumns, _.noop);
    layout.registerCustomMeasure(COLUMN, measureColumn);
    layout.registerPatcher(COLUMN, patchColumn);
});
