/**
 * Created by eitanr on 6/24/14.
 */
define([
    'lodash',
    'warmupUtilsLib',
    'layout/util/layout',
    'layout/util/svgLayoutUtils',
    'layout/specificComponents/svgShape/svgScaler'
], function (_,
             warmupUtilsLib,
             /** layout.layout */layout,
             svgLayoutUtils,
             svgScaler) {
    'use strict';

    function parseViewBox(viewBoxString) {
        const [x, y, width, height] = viewBoxString.split(' ');
        return {x, y, width, height};
    }

    function isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio) {
        // does browser supports css 'vector-effect: non-scaling-stroke'
        const isVectorEffect = warmupUtilsLib.svgFeatureDetection.flags().isVectorEffect;
        return strokeWidth > 0 && !maintainAspectRatio && !isVectorEffect;
    }

    function getSvgMeasures(nodesMap, id, svgId, viewBox) {
        const node = nodesMap[id];
        const svgNode = nodesMap[`${id}svg`];
        const boxBoundaries = viewBox ? parseViewBox(viewBox) : svgNode.getBBox();
        if (!boxBoundaries) {
            return;
        }

        const displayMode = node.getAttribute('data-display-mode');
        const maintainAspectRatio = displayMode !== 'stretch';
        const strokeWidth = parseInt(node.getAttribute('data-strokewidth'), 10);
        const requestedSize = {
            width: node.offsetWidth,
            height: node.offsetHeight
        };

        if (isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio)) {
            //const svgString = svgNode.outerHTML;

            requestedSize.width -= strokeWidth;
            requestedSize.height -= strokeWidth;

            return {
                polyfillScale: svgScaler.measure(
                    id,
                    nodesMap,
                    requestedSize,
                    boxBoundaries,
                    strokeWidth,
                    maintainAspectRatio)
            };
        }
        return {
            scale: svgLayoutUtils.getSvgScaleProps(
                boxBoundaries,
                strokeWidth,
                requestedSize,
                maintainAspectRatio)
        };
    }

    function measureShape(id, nodesMap) {
        if (!nodesMap[id]) {
            return;
        }

        const node = nodesMap[id];
        const svgId = node.getAttribute('data-svg-id');
        const svgElem = node.querySelector('svg');
        const preserveViewBox = node.getAttribute('data-preserve-viewbox') === 'preserve';
        const viewBox = preserveViewBox ? node.getAttribute('data-viewbox') : '';

        if (svgId && svgElem) {
            nodesMap[`${id}svg`] = svgElem;
            return getSvgMeasures(nodesMap, id, svgId, viewBox);
        }

        // find hook and call it
    }

    /**
     *
     * @param id
     * @param patchers
     * @param {layout.structureInfo} structureInfo
     * @param siteData
     */
    function layoutShape(id, patchers, svgMeasures) {
        const svgScaleProps = _.get(svgMeasures, 'scale');
        const polyfillScaleProps = _.get(svgMeasures, 'polyfillScale');

        if (svgScaleProps) {
            patchers.css(`${id}svg`, _.pick(svgScaleProps, ['strokeWidth']));
            patchers.attr(`${id}svg`, _.pick(svgScaleProps, ['preserveAspectRatio', 'viewBox']));
        } else if (polyfillScaleProps) {
            svgScaler.patch(id, patchers, polyfillScaleProps);
        }

        // else, do nothing
    }

    function layoutAndPatchShapes(shapeNodes, nodesMap) {
        const ids = [];
        const svgMeasuresMap = {};

        _.forEach(shapeNodes, shape => {
            const compId = shape.getAttribute('id');
            nodesMap[compId] = shape;
            svgMeasuresMap[compId] = measureShape(compId, nodesMap);
            ids.push(compId);
        });

        return patchers => {
            _.forEach(ids, compId => layoutShape(compId, patchers, svgMeasuresMap[compId]));
        };
    }

    function layoutShapeNodes(id, nodesMap) {
        if (nodesMap[id].getAttribute('data-svg-id')) {
            return layoutAndPatchShapes([nodesMap[id]], nodesMap);
        }
        const shapeNodes = nodesMap[id].querySelectorAll('*[data-svg-id]');
        return layoutAndPatchShapes(shapeNodes, nodesMap);
    }

    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.VectorImage', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.svgshape.SvgShape', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.PopupCloseIconButton', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaControls', layoutShapeNodes);
    // layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaOverlayControls', layoutShapeNodes); // see mediaOverlayControlsLayout
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.mobile.TinyMenu', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.LoginSocialBar', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.BackToTopButton', layoutShapeNodes);

    return {
        layoutShapeNodes
    };
});
