define([
    'lodash',
    'image-client-api',
    'layout/specificComponents/imageLayout',
    'warmupUtilsLib'
], function (
    _,
    imageClientLib,
    imageLayout,
    warmupUtilsLib
) {
    'use strict';

    const containerUtils = warmupUtilsLib.containerBackgroundUtils;
    const balataConsts = warmupUtilsLib.mediaConsts.balataConsts;

    /**
     *
     * @param bgData
     * @param compMeasure
     * @param siteData
     * @param videoQuality
     * @returns {{position: string, minWidth: number, minHeight: number, top: number, left: number}}
     * @param effectName
     */
    function getBackgroundVideoValues(bgData, compMeasure, siteData, videoQuality, effectName) {
        let bgStyle = {
            position: 'absolute',
            minWidth: 0,
            minHeight: 0,
            top: 0,
            left: 0
        };

        const mediaData = bgData.mediaRef;

        if (!mediaData || mediaData.type !== 'WixVideo') {
            return bgStyle;
        }

        const selectedQuality = _.find(mediaData.qualities, {quality: videoQuality});
        const scaleFactor = getVideoFillScale(compMeasure.width, compMeasure.height, selectedQuality.width, selectedQuality.height);

        const dimension = getVideoDimension(bgData.fittingType, scaleFactor, selectedQuality.width, selectedQuality.height);
        const position = getVideoPosition(bgData.alignType, dimension, compMeasure);

        bgStyle = {
            position: 'absolute',
            minWidth: dimension.width,
            minHeight: dimension.height,
            left: position.left,
            top: containerUtils.isFullScreenByEffect(effectName, siteData.renderFlags.renderFixedPositionBackgrounds) ? '' : position.top
        };

        return bgStyle;
    }

    function getVideoFillScale(containerWidth, containerHeight, videoWidth, videoHeight) {
        return {wScale: containerWidth / videoWidth, hScale: containerHeight / videoHeight};
    }

    function getVideoDimension(fittingType = imageClientLib.fittingTypes.SCALE_TO_FILL, videoScale, videoWidth, videoHeight) {
        let width, height, scale;
        switch (fittingType) {
            case imageClientLib.fittingTypes.SCALE_TO_FILL:
                scale = Math.max(videoScale.wScale, videoScale.hScale);
                width = Math.round(videoWidth * scale);
                height = Math.round(videoHeight * scale);
                break;
            case imageClientLib.fittingTypes.SCALE_TO_FIT:
                scale = Math.min(videoScale.wScale, videoScale.hScale);
                width = Math.round(videoWidth * scale);
                height = Math.round(videoHeight * scale);
                break;
        }
        return {width, height};
    }

    function getVideoPosition(alignType = imageClientLib.alignTypes.CENTER, videoSize, videoContainerSize) {
        const containerWidth = videoContainerSize.width;
        const containerHeight = videoContainerSize.compRootHeight;
        const verticalMiddle = Math.round((containerHeight - videoSize.height) / 2);
        const horizontalMiddle = Math.round((containerWidth - videoSize.width) / 2);
        const pos = {};
        const alignTypes = imageClientLib.alignTypes;
        switch (alignType) {
            case alignTypes.CENTER:
                pos.left = horizontalMiddle;
                pos.top = verticalMiddle;
                break;
            case alignTypes.LEFT:
                pos.left = 0;
                pos.top = verticalMiddle;
                break;
            case alignTypes.RIGHT:
                pos.left = containerWidth - videoSize.width;
                pos.top = verticalMiddle;
                break;
            case alignTypes.TOP:
                pos.left = horizontalMiddle;
                pos.top = 0;
                break;
            case alignTypes.BOTTOM:
                pos.left = horizontalMiddle;
                pos.top = containerHeight - videoSize.height;
                break;
            case alignTypes.TOP_LEFT:
                pos.left = 0;
                pos.top = 0;
                break;
            case alignTypes.TOP_RIGHT:
                pos.left = containerWidth - videoSize.width;
                pos.top = 0;
                break;
            case alignTypes.BOTTOM_LEFT:
                pos.left = 0;
                pos.top = containerHeight - videoSize.height;
                break;
            case alignTypes.BOTTOM_RIGHT:
                pos.left = containerWidth - videoSize.width;
                pos.top = containerHeight - videoSize.height;
                break;
        }

        return pos;
    }

    function getBgData(structureInfo) {
        if (structureInfo.designDataItem && structureInfo.designDataItem.background) {
            return structureInfo.designDataItem.background;
        }
        return structureInfo.dataItem.background;
    }

    function isVideoDataExist(structureInfo) {
        const background = getBgData(structureInfo);
        return !_.isEmpty(background.mediaRef.qualities);
    }

    function measureBgVideo(id, measureMap, nodesMap, structureInfo) {
        const posterId = id + balataConsts.POSTER;
        measureMap.custom[posterId] = {};
        imageLayout.measureNodeImage(posterId, measureMap, nodesMap, structureInfo);

        const videoWrapper = nodesMap[id];
        const videoNode = videoWrapper.firstChild;
        nodesMap[`${id}video`] = videoNode;
        measureMap.custom[id] = {};
        //const videoSource = videoNode.firstChild;
        //nodesMap[`${id }mp4`] = videoSource;

        if (isVideoDataExist(structureInfo)) {
            measureMap.custom[id] = {
                quality: videoWrapper.getAttribute('data-quality'),
                //src: videoSource.src,
                videoNode
            };
        } else {
            measureMap.custom[id] = {
                videoNode
            };
        }
    }

    function patchBgVideo(id, patchers, measureMap, structureInfo, siteData, videoHeight, parentDimensions) {
        //patch poster
        const bgData = getBgData(structureInfo);
        const width = parentDimensions.width;
        const height = parentDimensions.height;

        const imageDimensions = {
            width,
            height: videoHeight,
            top: 0
        };
        const posterQuality = {
            unsharpMask: {
                radius: 0.33,
                amount: 1.0,
                threshold: 0.0
            }
        };
        const imageData = _.assign(bgData.mediaRef.posterImageRef, {
            displayMode: bgData.fittingType,
            quality: posterQuality,
            devicePixelRatio: 1
        });

        imageLayout.patchNodeImage(`${id}poster`, patchers, measureMap, siteData, imageData, imageDimensions, bgData.alignType);
        const customMeasure = measureMap.custom[id];

        //patch video
        if (isVideoDataExist(structureInfo)) {
            const isDesktopDevice = !siteData.isTouchDevice();
            const effectName = containerUtils.getBgEffectName(structureInfo.behaviorsItem, isDesktopDevice, siteData.isMobileView());

            const quality = customMeasure.quality;
            const videoStyleAndPosition = getBackgroundVideoValues(bgData, {
                width,
                height: videoHeight,
                compRootHeight: height
            }, siteData, quality, effectName);

            patchers.attr(`${id}video`, {
                'width': videoStyleAndPosition.minWidth,
                'height': videoStyleAndPosition.minHeight
            });
            patchers.css(`${id}video`, videoStyleAndPosition);

            measureMap.width[`${id}video`] = videoStyleAndPosition.minWidth;
            measureMap.height[`${id}video`] = videoStyleAndPosition.minHeight;
            measureMap.top[`${id}video`] = videoStyleAndPosition.top;
            measureMap.left[`${id}video`] = videoStyleAndPosition.left;
        } else {
            //todo: image client api returns position :relative
            patchers.css(`${id}poster`, {position: 'absolute', top: '0px'});
            patchers.attr(`${id}video`, {src: ''});
            customMeasure.videoNode.load();
        }
    }

    return {
        patchBgVideo,
        measureBgVideo
    };
});
