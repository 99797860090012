/**
 * Created by eitanr on 6/24/14.
 */
define(['layout/util/layout',
    'zepto',
    'image-client-api',
    'lodash',
    'layout/specificComponents/imageLayout',
    'warmupUtilsLib'
], function (
    layout /** layout.layout */,
    $,
    imageClientLib,
    _,
    imageLayout,
    warmupUtilsLib
) {
    'use strict';

    const matrixScalingCalculations = warmupUtilsLib.matrixScalingCalculations;
    const galleriesCommonLayout = warmupUtilsLib.galleriesCommonLayout;

    function getSizeAfterScaling(displayerData, galleryHeight, galleryWidth, props, displayerNode) {
        const $displayer = $(displayerNode);
        return matrixScalingCalculations.getSizeAfterScaling({
            itemHeight: galleryHeight,
            itemWidth: galleryWidth,
            displayerData,
            imageMode: props.imageMode,
            heightDiff: parseInt($displayer.data('height-diff'), 10) || 0,
            widthDiff: parseInt($displayer.data('width-diff'), 10) || 0,
            bottomGap: parseInt($displayer.data('bottom-gap'), 10) || 0
        });
    }

    function measureDisplayer(compId, galleryHeight, galleryWidth, props, imageItems, displayerNode) {
        const $displayer = $(displayerNode);
        const imageIndex = $displayer.data('image-index');
        const imageData = _.defaults({displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL}, imageItems[imageIndex]);

        const sizeAfterScaling = getSizeAfterScaling(imageData, galleryHeight, galleryWidth, props, $displayer);
        const containerSize = galleriesCommonLayout.getContainerSize(sizeAfterScaling.imageWrapperSize, $displayer);

        return {
            id: displayerNode.id,
            imageData,
            imageNodeId: `${displayerNode.id}image`,
            imageWrapperId: `${displayerNode.id}imageWrapper`,
            containerSize,
            sizeAfterScaling
        };
    }

    function measureSlideShow(id, measureMap, nodesMap, structureInfo) {
        galleriesCommonLayout.measureFlexibleHeightGallery(id, measureMap, nodesMap);
        const itemsContainer = nodesMap[`${id}itemsContainer`];
        const displayers = $(itemsContainer).children();
        const $gallery = $(nodesMap[id]);
        const galleryHeight = measureMap.height[id] - parseInt($gallery.data('height-diff'), 10) || 0;
        const galleryWidth = measureMap.width[id] - parseInt($gallery.data('height-diff'), 10) || 0;
        const imageItems = structureInfo.dataItem.items;
        measureMap.custom[id] = {
            displayers: _.map(displayers, _.partial(measureDisplayer, id, galleryHeight, galleryWidth, structureInfo.propertiesItem, imageItems))
        };
    }


    function patchSlideShow(id, patchers, measureMap, structureInfo, siteData) {
        const props = structureInfo.propertiesItem;
        let galleryHeight = measureMap.height[id];
        const galleryWidth = measureMap.width[id];
        const isMobile = siteData.isMobileDevice() || siteData.isMobileView();
        const galleryDisplayers = measureMap.custom[id].displayers;
        if (_.isEmpty(galleryDisplayers)) {
            return;
        }
        if (props.imageMode === 'flexibleHeight') {
            const lastDisplayer = _.last(galleryDisplayers);
            galleryHeight = lastDisplayer.sizeAfterScaling.displayerSize.height;
        }

        _.forEach(galleryDisplayers, function (displayer) {
            patchers.css(displayer.id, {
                height: galleryHeight,
                width: galleryWidth
            });
            galleriesCommonLayout.updateImageWrapperSizes(patchers, displayer.imageWrapperId, displayer.sizeAfterScaling);
            imageLayout.patchNodeImage(displayer.imageNodeId, patchers, measureMap, siteData, displayer.imageData, displayer.containerSize, isMobile);
        });
    }

    function getChildrenIdToMeasure(id, nodesMap, structureInfo) {
        const res = [
            ['itemsContainer']
        ];
        _.forEach(structureInfo.dataItem.items, function (displayer) {
            const childImageComponent = {pathArray: [displayer.id, 'image'], type: 'core.components.Image'};
            const imageWrapper = [displayer.id, 'imageWrapper'];
            const displayerPath = [displayer.id];
            res.push(childImageComponent, imageWrapper, displayerPath);
        });
        return res;
    }

    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.SlideShowGallery');
    layout.registerCustomMeasure('wysiwyg.viewer.components.SlideShowGallery', measureSlideShow);
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.SlideShowGallery', getChildrenIdToMeasure);
    layout.registerPatcher('wysiwyg.viewer.components.SlideShowGallery', patchSlideShow);

    return {measureSlideShow, getChildrenIdToMeasure, patchSlideShow};
});
