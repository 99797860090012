define(['zepto', 'layout/util/layout', 'warmupUtilsLib', 'experiment', 'layout/util/rootLayoutUtils', 'lodash', 'layout/specificComponents/memberLoginSectionLayout'],
    function ($, /** layout.layout */ layout, warmupUtilsLib, experiment, rootLayoutUtils, _, memberLoginSectionLayout) {
        'use strict';

        const MENU_CONTAINER_SKIN_PART_ID = 'menuContainer';
        const MENU_BUTTON_SKIN_PART_ID = 'menuButton';
        const MENU_ITEMS_SKIN_PART_ID = 'menuItems';
        const MENU_BACKGROUND_SKIN_PART_ID = 'menuBackground';
        const OVERLAY_SKIN_PART_ID = 'fullScreenOverlay';
        const MEMBER_SECTION = 'tinyMenuMemberSection';
        const MENU_MARGIN = 20;
        const CLASSIC_SKIN = 'wysiwyg.viewer.skins.mobile.TinyMenuSkin';

        function hasMemberSectionData(structureInfo) {
            return _.get(structureInfo, 'dataItem.loginSocialBarRef');
        }

        function hasTinyMenuMemberSectionNode(id, nodesMap, structureInfo) {
            const tinyMenuMemberSectionId = id + MEMBER_SECTION;
            return nodesMap[tinyMenuMemberSectionId] && hasMemberSectionData(structureInfo);
        }

        function measureTinyMenuMemberSectionIfNeeded(id, measureMap, nodesMap, structureInfo) {
            if (hasTinyMenuMemberSectionNode(id, nodesMap, structureInfo)) {
                memberLoginSectionLayout.measure(id, measureMap, nodesMap, structureInfo, MEMBER_SECTION);
            }
        }

        function patchTinyMenuMemberSectionIfNeeded(id, patchers, measureMap, structureInfo, siteData) {
            const tinyMenuMemberSectionId = id + MEMBER_SECTION;
            if (measureMap.custom[id].isOpen && measureMap.width[tinyMenuMemberSectionId] && hasMemberSectionData(structureInfo)) {
                memberLoginSectionLayout.patch(id, patchers, measureMap, structureInfo, siteData, MEMBER_SECTION);
            }
        }

        function getChildrenIdToMeasure(id, nodesMap, structureInfo, siteData) {
            let res = [[MENU_CONTAINER_SKIN_PART_ID], [MENU_BUTTON_SKIN_PART_ID], [MENU_ITEMS_SKIN_PART_ID], [OVERLAY_SKIN_PART_ID], [MENU_BACKGROUND_SKIN_PART_ID]];
            const tinyMenuMemberSectionId = id + MEMBER_SECTION;
            nodesMap[tinyMenuMemberSectionId] = nodesMap[id].querySelector(`#${tinyMenuMemberSectionId}`);

            if (hasTinyMenuMemberSectionNode(id, nodesMap, structureInfo)) {
                res.push({pathArray: [MEMBER_SECTION], type: 'wysiwyg.viewer.components.mobile.TinyMenuMemberSection'});
                const sectionChildrenIds = memberLoginSectionLayout.getChildrenIdToMeasure(siteData, tinyMenuMemberSectionId, nodesMap, structureInfo, MEMBER_SECTION);
                res = res.concat(sectionChildrenIds);
            }
            return res;
        }

        function patchTinyMenu(id, patchers, measureMap, structureInfo, siteData) {
            const menuContainerId = id + MENU_CONTAINER_SKIN_PART_ID;
            const siteWidth = rootLayoutUtils.getRootWidth(siteData.getSiteWidth(), measureMap, structureInfo.rootId);
            const containerWidth = siteWidth - MENU_MARGIN * 2; // eslint-disable-line no-mixed-operators

            const isHeaderFixedPosition = _.get(measureMap.custom.SITE_HEADER, 'isFixedPosition');
            const isTinyMenuFixedPosition = structureInfo.layout.fixedPosition;

            const wixAdsHeight = _.get(measureMap, ['height', 'WIX_ADStop'], 0);

            patchTinyMenuMemberSectionIfNeeded(id, patchers, measureMap, structureInfo, siteData);

            let tinyMenuButtonMarginTop = 0;

            if (experiment.isOpen('sv_fixedMobileHeader')) {
                if (!isHeaderFixedPosition && isTinyMenuFixedPosition) {
                    tinyMenuButtonMarginTop = wixAdsHeight;
                }
            } else {
                tinyMenuButtonMarginTop = isTinyMenuFixedPosition ? wixAdsHeight : 0;
            }

            patchers.css(id + MENU_BUTTON_SKIN_PART_ID, {
                marginTop: `${tinyMenuButtonMarginTop}px`
            });


            const isClassicSkin = _.get(structureInfo, ['styleItem', 'skin'], CLASSIC_SKIN) === CLASSIC_SKIN;

            if (isClassicSkin) {
                patchers.css(menuContainerId, {
                    maxHeight: (isTinyMenuFixedPosition ? measureMap.clientHeight - wixAdsHeight : measureMap.height.masterPage) - _.get(measureMap, ['custom', id, 'menuContainerTop'], 0),
                    width: `${containerWidth}px`
                });
            } else {
                const maxHeight = measureMap.height[id + MENU_BACKGROUND_SKIN_PART_ID] - measureMap.top[menuContainerId];

                patchers.css(menuContainerId, {
                    paddingTop: `${wixAdsHeight}px`,
                    maxHeight: `${maxHeight}px`
                });

                patchers.css(id + OVERLAY_SKIN_PART_ID, {
                    height: `${measureMap.height.screen + 1}px`}
                );
            }
        }

        function measureTinyMenu(id, measureMap, nodesMap, structureInfo) {
            const menuContainerId = id + MENU_CONTAINER_SKIN_PART_ID;
            const siteRootNode = $('#SITE_ROOT')[0];
            const tinyMenuRect = warmupUtilsLib.domMeasurements.getElementRect(nodesMap[id], siteRootNode);

            _.set(measureMap.custom, ['SITE_HEADER', 'isFixedPosition'].join('.'), $('#SITE_HEADER').css('position') === 'fixed');
            measureMap.custom[id] = {
                menuContainerTop: tinyMenuRect.bottom
            };

            measureMap.top[menuContainerId] = warmupUtilsLib.domMeasurements.getBoundingRect(nodesMap[menuContainerId]).top;
            if (nodesMap[id + MENU_BACKGROUND_SKIN_PART_ID]) {
                measureMap.height[id + MENU_BACKGROUND_SKIN_PART_ID] = warmupUtilsLib.domMeasurements.getElementRect(nodesMap[id + MENU_BACKGROUND_SKIN_PART_ID]).height;
            }
            measureMap.height[id] = measureMap.height[id + MENU_BUTTON_SKIN_PART_ID];

            const isOpen = /menuOpen/.test(nodesMap[id + MENU_BUTTON_SKIN_PART_ID].className);
            measureMap.custom[id].isOpen = isOpen;
            if (isOpen) {
                const menuItemsRect = warmupUtilsLib.domMeasurements.getElementRect(nodesMap[id + MENU_ITEMS_SKIN_PART_ID], siteRootNode);

                measureMap.minHeight.masterPage = Math.max(measureMap.minHeight.masterPage || 0,
                    menuItemsRect.bottom);

                measureTinyMenuMemberSectionIfNeeded(id, measureMap, nodesMap, structureInfo);
            }
        }

        layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.mobile.TinyMenu', getChildrenIdToMeasure);
        layout.registerCustomMeasure('wysiwyg.viewer.components.mobile.TinyMenu', measureTinyMenu);
        layout.registerPatcher('wysiwyg.viewer.components.mobile.TinyMenu', patchTinyMenu);
    });
