define(['lodash', 'layout/util/layout', 'layout/specificComponents/balataLayout'], function (_, /** layout.layout */ layout, balataLayout) {
    'use strict';

    //To Use mediaContainer with background register you component here

    const CONTAINER_POSTFIX = 'container';

    function measureMediaContainer(id, measureMap, nodesMap, structureInfo) {
        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);

        measureMap.width[id] = structureInfo.layout.width || nodesMap[id].clientWidth || measureMap.width[id];
    }

    function patchMediaContainer(id, patchers, measureMap, structureInfo, siteData) {
        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    const registeredComponentTypes = [
        'wysiwyg.viewer.components.MediaBox',
        'wysiwyg.viewer.components.HoverBox',
        'wysiwyg.viewer.components.MediaPlayer',
        'wysiwyg.viewer.components.MediaContainer'
    ];

    _.forEach(registeredComponentTypes, function (compType) {
        layout.registerCustomMeasure(compType, measureMediaContainer);
        layout.registerPureDomHeightMeasure(compType);
        layout.registerRequestToMeasureChildren(compType, balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE.concat([[CONTAINER_POSTFIX]]));
        layout.registerPatcher(compType, patchMediaContainer);
    });

    return {
        measure: measureMediaContainer,
        patch: patchMediaContainer
    };
});
