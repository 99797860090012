define(['lodash', 'layout/util/layout'], function (_, /** layout.layout */ layout) {
    'use strict';


    function getOpenDirection(viewPortHeight, menuPosY) {
        const viewPortMiddle = Math.floor(viewPortHeight / 2);
        return viewPortMiddle > menuPosY ? 'down' : 'up';
    }

    function patchVerticalMenuDesktop(measureMap, id, patchers) {
        const custom = measureMap.custom[id];
        const menuPosY = measureMap.absoluteTop[id] - custom.pageYOffset;
        const openDirection = `subMenuOpenDir-${getOpenDirection(custom.screenHeight, menuPosY)}`;

        patchers.attr(id, {'data-open-direction': openDirection});
    }

    /**
     *
     * @param id
     * @param patchers
     * @param measureMap
     * @param structureInfo
     * @param {core.SiteData} siteData
     */
    function patchVerticalMenu(id, patchers, measureMap, structureInfo, siteData) {
        const listId = `${id}menuContainer`;

        if (!measureMap.height[listId] || !measureMap.width[listId]) {
            return;
        }

        if (siteData.isMobileView()) {
            patchers.css(listId, {
                height: measureMap.height[listId],
                width: measureMap.width[listId]
            });
        } else {
            patchVerticalMenuDesktop(measureMap, id, patchers, structureInfo, siteData);
        }
    }

    function measureVerticalMenuDesktop(id, measureMap, nodesMap) {
        const listId = `${id}menuContainer`;

        if (!nodesMap[listId]) {
            return;
        }

        measureMap.custom[id] = {
            screenHeight: measureMap.height.screen,
            pageYOffset: window.pageYOffset
        };
        measureMap.width[id] = nodesMap[listId].offsetWidth;
        measureMap.height[id] = nodesMap[listId].offsetHeight;
    }

    /**
     *
     * @param id
     * @param measureMap
     * @param nodesMap
     */
    function measureVerticalMenuCustom(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
        if (isMobileView()) {
            measureMap.height[`${id}menuContainer`] = measureMap.height[id];
            measureMap.width[`${id}menuContainer`] = measureMap.width[id];
        } else {
            measureVerticalMenuDesktop(id, measureMap, nodesMap);
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', [['menuContainer']]);
    layout.registerCustomMeasure('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', measureVerticalMenuCustom);
    layout.registerPatcher('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', patchVerticalMenu);

    return {};
});
