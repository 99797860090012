define([
    'lodash',
    'layout/util/layout',
    'warmupUtilsLib',
    'layout/util/optimizedCssImageLayout',
    'layout/specificComponents/imageLayout'
], function (_, layout, warmupUtilsLib, optimizedCssImageLayout, imageLayout) {
    'use strict';

    const balataConsts = warmupUtilsLib.mediaConsts.balataConsts;

    function isEmptyOrExternalUri(uri) {
        return !uri || warmupUtilsLib.urlUtils.isExternalUrl(uri);
    }

    function getImageTransformData(fittingType, alignType, imageData, targetDimensions, siteData, htmlTag) {
        const pixelAspectRatio = siteData.mobile.getDevicePixelRatio();
        const target = {
            width: targetDimensions.width,
            height: targetDimensions.height,
            alignment: alignType,
            htmlTag: htmlTag || 'bg',
            pixelAspectRatio
        };
        const src = {id: imageData.uri, width: imageData.width, height: imageData.height};
        const imageQualityFilters = _.defaults({quality: 85}, imageData.quality || {});
        return warmupUtilsLib.getImageClientLib().getData(fittingType, src, target, imageQualityFilters, siteData.browser);
    }

    function measureLegacyBgImageStrip(id, measureMap, nodesMap/*, siteData, structureInfo*/) {
        const legacyBgImageId = `${id}bg`;
        if (!nodesMap[legacyBgImageId]) {
            return;
        }
        measureMap.custom[id] = {};
        optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[id], nodesMap[legacyBgImageId]);
    }

    function measureBgImageBalata(balataNodeId, measureMap, nodesMap, parentStructureInfo) {
        const imageCompNodeId = balataNodeId + balataConsts.MEDIA + balataConsts.IMAGE;
        const nodeType = nodesMap[imageCompNodeId].getAttribute('data-type');
        
        measureMap.custom[balataNodeId] = measureMap.custom[balataNodeId] || {};
        measureMap.custom[balataNodeId].type = nodeType;

        if (nodeType === balataConsts.IMAGE) {
            imageLayout.measureNodeImage(imageCompNodeId, measureMap, nodesMap, parentStructureInfo);
        } else {
            const innerImageId = `${imageCompNodeId}image`;
            //TODO: innerImageId should be added to node map (balata)
            nodesMap[innerImageId] = nodesMap[imageCompNodeId].querySelector(`#${innerImageId}`);
            const innerImageNode = nodesMap[innerImageId];
            measureMap.custom[imageCompNodeId] = {};
            optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[imageCompNodeId], innerImageNode);
        }
    }

    function getImageProperties(balataData, nodeType, parentDimensions, innerImageHeight, siteData) {
        const width = parentDimensions.width;
        const height = innerImageHeight || parentDimensions.height;

        const imageData = getImageData(balataData);
        const fittingType = balataData.fittingType;
        const alignType = balataData.alignType;
        return getImageTransformData(fittingType, alignType, imageData, {
            width,
            height
        }, siteData, nodeType);
    }

    function patchBgImage(balataNodeId, imageCompNodeId, patchers, measureMap, structureInfo, siteData, innerImageHeight, parentDimensions) {
        const nodeType = measureMap.custom[balataNodeId].type;
        const balataData = getBalataData(structureInfo);
        if (nodeType === balataConsts.IMAGE) {
            const containerSize = {
                width: parentDimensions.width,
                height: innerImageHeight
            };

            const imageData = _.assign({displayMode: balataData.fittingType}, getImageData(balataData));

            imageLayout.patchNodeImage(imageCompNodeId, patchers, measureMap, siteData, imageData, containerSize, balataData.alignType);
        } else {
            const innerImageId = `${imageCompNodeId}image`;
            const imageTransformData = getImageProperties(balataData, 'bg', parentDimensions, innerImageHeight, siteData);
            const cssValues = _.assign({height: innerImageHeight}, imageTransformData.css.container);
            optimizedCssImageLayout.patchCssImage(measureMap.custom[imageCompNodeId], innerImageId, patchers, cssValues, imageTransformData.uri, siteData);
        }
    }


    function getBalataData(structureInfo) {
        if (_.isUndefined(structureInfo.designDataItem)) {
            return structureInfo.dataItem.background;
        }
        return structureInfo.designDataItem.background;
    }

    function getImageData(balataData) {
        let imageData = balataData.mediaRef;
        // If media is not image but video, go another level deeper to posterImage
        if (imageData && imageData.type === 'WixVideo') {
            imageData = imageData.posterImageRef;
        }
        return imageData;
    }

    function patchLegacyBgImageStrip(id, patchers, measureMap, structureInfo, siteData, parentDimensions) {
        const customMeasureData = measureMap.custom[id];
        const legacyBgImageId = `${id}bg`;
        if (customMeasureData) {
            const imageData = structureInfo.dataItem;
            if (!imageData || isEmptyOrExternalUri(imageData.uri)) {
                return;
            }
            const fittingType = structureInfo.propertiesItem.fittingType;
            const alignType = structureInfo.propertiesItem.alignType;
            const imageTransformData = getImageTransformData(fittingType, alignType, imageData, parentDimensions, siteData);
            optimizedCssImageLayout.patchCssImage(customMeasureData, legacyBgImageId, patchers, imageTransformData.css.container, imageTransformData.uri, siteData);
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.background.bgImage', [['image']]);
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.BgImageStrip', [['bg']]);

    return {
        measureLegacyBgImageStrip,
        measureBgImageBalata,
        patchBgImage,
        patchLegacyBgImageStrip
    };
})
;
